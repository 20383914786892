import React, { useEffect, useRef, useState } from "react"
import { Grid, Typography, Box, useMediaQuery } from "@mui/material"
import axios from "axios"
import { UrlBuilder } from "helpers/UrlBuilder"
import portalImage from "assets/images/portal/mockup.jpg"
import HomeIcon from "@mui/icons-material/Home"
import { makeStyles, useTheme } from "@mui/styles"
import { callApi, selectApi } from "store/reducers/apiSlice"
import { useDispatch, useSelector } from "react-redux"
import useAuth from "hooks/useAuth"
import { useNavigate } from "react-router"
import { getAccessToken } from "helpers/AuthUtils"
import JobListSimpleBar from "./JobListSimpleBar"
import JobPostsDisplay from "./JobPostDisplay"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles({
  nameContainer: {
    display: "flex",
    alignItems: "start",
    width: "100%",
    Padding: "20px",
    flex: 2,
  },
  iconContainer: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    width: "auto",
    height: "100%",
  },
  icon: {
    fontSize: "40px", // Adjust the icon size as needed
    color: "#6BD425", // Use the specified icon color
  },
  textContainer: {
    color: "#000000",
  },
  favoritesContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  favorites: {
    textAlign: "left",
  },
})

const JobDetails = ({
  category,
  setPage,
  setSearch,
  search,
  setSearchResults,
}) => {
  const { t, i18n } = useTranslation()
  const [moreCvDetails, setMoreCvDetails] = useState("Description")
  const [selectJobIndex, setSelectJobIndex] = useState(0)
  const [message, setMessage] = useState("")
  const [refetch, setRefetch] = useState(false)
  const classes = useStyles()
  const { allJobPostsList, jobCategories, savedJob } = useSelector(selectApi)
  const navigate = useNavigate()

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [openDrawer, setOpenDrawer] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const handleCardClick = (index) => {
    setSelectJobIndex(index)
    setOpenDrawer(true)
  }
  const convertToBengaliDigits = (number) => {
    const bengaliDigits = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"]
    return String(number)
      .split("")
      .map((digit) => bengaliDigits[digit] || digit)
      .join("")
  }
  // const findCategoriesName = (id) => {
  //   let categories;
  //   jobCategories?.data.forEach((c) => {
  //     if (c.sub_categories) {
  //       c.sub_categories.forEach((sc) => {
  //         if (sc.id == id) {
  //           categories = sc;
  //         }
  //       });
  //       if (c.id == id) {
  //         categories = c;
  //       }
  //     } else if (c.id == id) {
  //       categories = c;
  //     }
  //   });
  //   return categories ? categories?.name : "";
  // };
  const findCategoriesName = (id) => {
    let categories

    jobCategories?.data.forEach((c) => {
      if (c.sub_categories) {
        c.sub_categories.forEach((sc) => {
          if (sc.id == id) {
            categories = sc // Found the sub-category
          }
        })
        if (c.id == id) {
          categories = c // Found the main category
        }
      } else if (c.id == id) {
        categories = c // Found the main category without sub-categories
      }
    })

    // Return the Bengali name if found, otherwise return the English name
    return categories
      ? i18n.language === "bn"
        ? categories.name_bn
        : categories.name
      : ""
  }

  const isLoggedIn = useAuth()

  const selectedJobPost = isLoggedIn
    ? allJobPostsList?.data?.job_posts?.[selectJobIndex]?.job_post
    : allJobPostsList?.data?.public_job_posts?.[selectJobIndex]

  const selectedJobPostId = selectedJobPost?.id || null

  const handleModal = () => {
    if (!isLoggedIn) {
      navigate("/login")
    } else {
      handleOpen()
    }
  }

  const handleApplyJob = async (event) => {
    console.log("send request")

    const data = {
      job_post_id: selectedJobPostId,
      job_answer: message,
    }

    if (!selectedJobPostId) {
      alert("Invalid job post selected.")
      return
    }

    try {
      const res = await axios.post(
        UrlBuilder.jobServiceApi("job/posts/applications/apply"),
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`, // Include the access token in the Authorization header
          },
        }
      )

      setRefetch(true)
    } catch (error) {
      console.error("Error:", error)
      alert("Error: Already Requested or Failed to Apply")
    }

    setMessage("")
    setRefetch(false)
  }

  const handleSaveJob = async (event) => {
    console.log("save job")
    const jobId = allJobPostsList?.data?.job_posts[selectJobIndex]?.job_post?.id

    try {
      const response = await axios.get(
        UrlBuilder.jobServiceApi(`job/posts/${jobId}/save`),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      )

      setRefetch(true)
      return response.data
    } catch (error) {
      console.error("Error saving job post:", error)
      alert("Already Saved")
    }
    setRefetch(false)
  }
  const handleUnsaveJob = async (event) => {
    console.log("save job")
    const jobId = allJobPostsList?.data?.job_posts[selectJobIndex]?.job_post?.id

    try {
      const response = await axios.get(
        UrlBuilder.jobServiceApi(`job/posts/${jobId}/unsave`),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      )

      setRefetch(true)
      return response.data
    } catch (error) {
      console.error("Error unsaving job post:", error)
      alert("Already Unsaved")
    }
    setRefetch(false)
  }

  const { appliedJob } = useSelector(selectApi)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      callApi({
        operationId: UrlBuilder.jobServiceApi(`job/posts/applications`),
        output: "appliedJob",
      })
    )
    dispatch(
      callApi({
        operationId: UrlBuilder.jobServiceApi(`job/posts/saved`),
        output: "savedJob",
      })
    )
  }, [refetch])

  const appliedJobIds =
    appliedJob?.data?.job_application_details?.map(
      (job) => job?.job_post?.id
    ) || []
  const savedJobIds =
    savedJob?.data?.saved_job_posts?.map((job) => job?.job_post?.id) || []

  const isApplied = appliedJobIds.find((id) => id === selectedJobPostId)
  const isSaved = savedJobIds.find((id) => id === selectedJobPostId)
  const jobCount = allJobPostsList?.data?.total

  return (
    <Grid
      container
      maxHeight={`calc(100vh - 80px)`}
      overflow={"auto"}
      spacing={0}
    >
      <Grid
        item
        md={6}
        sm={12}
        xs={12}
        sx={{
          //padding: "20px",
          px: "20px",
          backgroundColor: "primary.lighter",
          position: "relative",
        }}
        style={{ height: window.innerHeight }}
        maxHeight={`calc(100vh - 80px)`}
        overflow={"auto"}
        id="scrollableDiv"
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 10,
            pt: "40px",
            pb: "5px",
            backgroundColor: "#E3ECEA",
          }}
        >
          <Typography
            fontWeight={300}
            fontSize={{ xs: "25px", md: "30px", sm: "30px", lg: "38px" }}
            color="#444444"
          >
            {t("app.worker.cv.resumeCount", {
              count:
                i18n.language === "bn"
                  ? convertToBengaliDigits(jobCount)
                  : jobCount,
            })}{" "}
            {t("app.worker.cv.cvName")}{" "}
            {category ? findCategoriesName(category.id) : "All"}
          </Typography>
          <Typography variant="subtitle1" gutterBottom color="secondary.main">
            from{" "}
            {search?.subDistrictId !== "" || null
              ? `${search?.subDistrictName}, `
              : ""}{" "}
            {search?.districtId !== "" || null
              ? `${search?.districtName}`
              : "all"}
          </Typography>
        </Box>
        <Grid>
          <Grid item xs={12}>
            <JobListSimpleBar
              setSearch={setSearch}
              data={allJobPostsList?.data}
              search={search}
              setSearchResults={setSearchResults}
              sx={{
                overflowX: "hidden",
                height: "calc(100vh - 50vh)",
                minHeight: "70vh",
              }}
              setPage={setPage}
              //setSelectJobIndex={setSelectJobIndex}
              handleCardClick={handleCardClick}
              selectJobIndex={selectJobIndex}
            />
          </Grid>
        </Grid>
      </Grid>
      <JobPostsDisplay
        setRefetch={setRefetch}
        refetch={refetch}
        isMobile={isMobile}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        allJobPostsList={allJobPostsList}
        classes={classes}
        portalImage={portalImage}
        isLoggedIn={isLoggedIn}
        selectJobIndex={selectJobIndex}
        isSaved={isSaved}
        isApplied={isApplied}
        handleUnsaveJob={handleUnsaveJob}
        handleSaveJob={handleSaveJob}
        handleModal={handleModal}
        message={message}
        setMessage={setMessage}
        handleApplyJob={handleApplyJob}
        open={open}
        handleClose={handleClose}
        moreCvDetails={moreCvDetails}
        useStyles={useStyles}
        setMoreCvDetails={setMoreCvDetails}
      />
    </Grid>
  )
}

export default JobDetails
