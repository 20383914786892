// third-party
import { createSlice } from "@reduxjs/toolkit"

// project imports
import axios from "utils/axios"
import { dispatch } from "../index"
import { getAccessToken, getUserIdAndName } from "helpers/AuthUtils"

// ----------------------------------------------------------------------

const accessToken = getAccessToken()
const initialState = {
  error: null,
  chats: [],
  user: {},
  users: [],
}

const chat = createSlice({
  name: "chat",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload
    },

    // GET USER
    getUserSuccess(state, action) {
      state.user = action.payload
    },

    // GET USER CHATS
    getUserChatsSuccess(state, action) {
      state.chats = action.payload
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.users = action.payload
    },
  },
})

// Reducer
export default chat.reducer

// ----------------------------------------------------------------------

export function getUser(id) {
  return async () => {
    try {
      const response = await axios.post("/api/chat/users/id", { id })
      dispatch(chat.actions.getUserSuccess(response.data))
    } catch (error) {
      dispatch(chat.actions.hasError(error))
    }
  }
}

export function getUserChats(chatId) {
  return async () => {
    try {
      const response = await axios.get(
        `https://api.gorillajobs.app/api/v1/messaging/message/${chatId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      dispatch(chat.actions.getUserChatsSuccess(response.data.data))
    } catch (error) {
      dispatch(chat.actions.hasError(error))
    }
  }
}

export function insertChat(chat) {
  return async () => {
    try {
      await axios.post(
        `https://api.gorillajobs.app/api/v1/messaging/message`,
        chat,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
    } catch (error) {
      dispatch(chat.actions.hasError(error))
    }
  }
}

// export function getUsers() {
//   return async () => {
//     try {
//       const response = await axios.get('/api/chat/users');
//       dispatch(chat.actions.getUsersSuccess(response.data.users));
//     } catch (error) {
//       dispatch(chat.actions.hasError(error));
//     }
//   };
// }

// export const getChatId = async (Id) => {
//   const { userId } = getUserIdAndName() // Get userId from userData

//   try {
//     const response = await axios.get(
//       `https://api.gorillajobs.app/api/v1/messaging/chat/chatlist/${userId}/${Id}`,
//       {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       }
//     )

//     console.log("getChatId response -", response.data)
//     return response.data
//   } catch (e) {
//     console.log("Error in contacting getChatId", e)
//   }
// }
// export const getChatId = async (contactId) => {
//   const { userId } = getUserIdAndName() // Get userId from userData

//   try {
//     const response = await axios.get(
//       `https://api.gorillajobs.app/api/v1/messaging/chat/chatlist/${userId}/${contactId}`,
//       {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       }
//     )

//     console.log("getChatId response -", response.data)
//     return response.data // Return the response data
//   } catch (e) {
//     console.log("Error in contacting getChatId", e)
//     return { status: "fail", message: "Failed to get chat ID" } // Return an error response
//   }
// }

// export const createChat = async (reqData) => {
//   const { userId, fullName } = getUserIdAndName() // Get userId and fullName

//   try {
//     const response = await axios.post(
//       "https://api.gorillajobs.app/api/v1/messaging/chat",
//       {
//         ...reqData, // Pass other request data
//         sender_id: userId,  // Attach fullName if needed
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       }
//     )

//     console.log("createChat response -", response.data)
//     return response.data
//   } catch (e) {
//     console.log("Error in contacting createChat", e)
//   }
// }
// export const createChat = async (reqData) => {
//   const { userId, fullName } = getUserIdAndName() // Get userId and fullName

//   try {
//     const response = await axios.post(
//       "https://api.gorillajobs.app/api/v1/messaging/chat",
//       {
//         ...reqData, // Pass other request data
//         sender_id: userId, // Attach senderId
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       }
//     )

//     console.log("createChat response -", response.data)
//     return response.data // Return the response data
//   } catch (e) {
//     console.log("Error in contacting createChat", e)
//     return { status: "fail", message: "Failed to create chat" } // Return an error response
//   }
// }

export const getChatId = async (employerUserId) => {
  const { userId } = getUserIdAndName() // Get userId from userData
  try {
    const response = await axios.get(
      `https://api.gorillajobs.app/api/v1/messaging/chat/chatlist/${userId}/${employerUserId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    return response.data // Return data directly
  } catch (e) {
    console.log("Error in contacting getChatId", e)
    return { status: "fail" } // Return fail status for better handling
  }
}

export const createChat = async (reqData) => {
  const { userId, fullName } = getUserIdAndName() // Get userId and fullName
  try {
    const response = await axios.post(
      "https://api.gorillajobs.app/api/v1/messaging/chat",
      {
        ...reqData,
        sender_id: userId, // Ensure sender_id is included
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    return response.data // Return data directly
  } catch (e) {
    console.log("Error in contacting createChat", e)
    return { status: "fail" } // Return fail status for better handling
  }
}
