import React from "react"
import SimpleBar from "components/third-party/SimpleBar"
import portalImage from "../../../assets/images/portal/mockup.jpg"
import { Box, Card, Grid, Typography } from "@mui/material"
import CvCard from "pages/search/Cv/CvCard"
import { makeStyles } from "@mui/styles"
import InfiniteScroll from "react-infinite-scroll-component"
import Shimmer from "pages/search/Job/Shimmer"
import { useSelector } from "react-redux"
import { selectApi } from "store/reducers/apiSlice"

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    overflow: "hidden",
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    border: "1px solid #00D42A33",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    overflow: "hidden",
    "&:hover": {
      "&:hover": { boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" },
      cursor: "pointer",
    },
  },
  profileDetails: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(2),
    textAlign: "left",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
      alignItems: "flex-start",
      textAlign: "left",
      marginTop: 0,
    },
  },
  iconContainer: {
    borderRadius: "25px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    height: "50px",
    margin: 20,
  },
  icon: {
    fontSize: "40px",
    color: "#6BD425",
  },
  textContainer: {
    color: "#000000",
    flex: 1,
    width: "250px",
  },
  favoritesContainer: {
    display: "flex",
    alignItems: "center",
    width: "120px",
    height: "48px",
  },
  favorites: {
    width: "50px",
    height: "30px",
    alignItems: "right",
    justifyContent: "right",
    textAlign: "right",
  },
  locationContainer: {
    display: "flex",
    alignItems: "baseline",
    gap: "2px",
  },
  rightSection: {
    // display: "flex",
    // flexDirection: "column",
    // textAlign: "right",
    // marginTop: "10px",
    // marginLeft: "70px",
    // [theme.breakpoints.down("sm")]: {
    //   alignItems: "flex-end",
    //   textAlign: "right",
    //   marginTop: 0,
    // },
  },
}))
const CvListSimpleBar = ({
  data,
  data2,
  findCategoriesName,
  findEducation,
  handleClick,
  setLimit,
  limit,
  selectCvIndex,
}) => {
  const { cv_requested } = useSelector(selectApi)
  const classes = useStyles()
  return (
    <Box sx={{ width: "100%" }}>
      {data2?.data?.job_request?.length === 0 ? (
        <Box sx={{ alignItems: "center", textAlign: "center", margin: 30 }}>
          <Typography variant="h2" fontSize={30} color="primary.main">
            No Data
          </Typography>
        </Box>
      ) : (
        <Box sx={{ p: 1 }}>
          <InfiniteScroll
            dataLength={cv_requested?.data?.job_request?.length || 0}
            next={() => {
              setLimit((prev) => prev + 10)
            }}
            hasMore={
              data2?.data?.total > cv_requested?.data?.job_request?.length
            }
            loader={<Shimmer />}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            scrollableTarget="scrollableDiv" // Add this line
            // below props only if you need pull down functionality
          >
            {data2?.data?.job_request?.map((item, index) => (
              <Grid
                item
                key={`EmployeeCard - ${index}`}
                m={2}
                onClick={() => handleClick(index, item.worker_user_id)}
                sx={{
                  border:
                    selectCvIndex === index ? "2px solid #39FF14" : "none",
                  borderRadius: "8px",
                }}
              >
                <Card className={classes.card}>
                  <Box>
                    <Typography variant="h6" fontSize={"15px"} fontWeight={700}>
                      Name: {item?.worker_username}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontSize={"14px"}
                      sx={{ color: "#808481", flexWrap: "wrap" }}
                    >
                      Message: {item.message}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      fontSize={"13px"}
                      sx={{
                        backgroundColor:
                          item.status === "ACCEPTED" ? "#00d42a" : "gold",
                        py: "2px",
                        px: "4px",
                        borderRadius: "5px",
                      }}
                    >
                      {item.status}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            ))}
          </InfiniteScroll>
        </Box>
      )}
    </Box>
  )
}
export default CvListSimpleBar
