import React, { useEffect, useState } from "react"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import CvDetails from "./CvDetails"
import Filter from "../Filter"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { callApi, selectApi } from "store/reducers/apiSlice"
import { UrlBuilder } from "helpers/UrlBuilder"
import useAuth from "hooks/useAuth"
import Button from "@mui/material/Button"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import useMediaQuery from "@mui/material/useMediaQuery"
import { getUserRole } from "helpers/AuthUtils"
import { Typography } from "@mui/material"
import Loadable from "components/Loadable"
import CircularProgress from "@mui/material/CircularProgress"

const CvDetailsNew = () => {
  const [category, setCategory] = useState(null)
  const [refetch, setRefetch] = useState(false)
  const [clear, setClear] = useState(false)
  const navigate = useNavigate()
  const role = getUserRole()
  console.log("rile", role)
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const [loading, setLoading] = useState(false)

  const [searchResults, setSearchResults] = useState(false)
  const isLoggedIn = useAuth()
  const [subCategories, setSubCategories] = useState(null)
  const [search, setSearch] = useState({
    minJobExperience: "",
    minAge: "",
    minEducation: "",
    gender: "",
    maxAge: "",
    selectedJobType: "",
    selectedCategory: "",
    districtId: "",
    subDistrictId: "",
    districtName: "",
    subDistrictName: "",
    category: null,
    clear: false,
    limit: 10,
  })
  const { jobCategories } = useSelector(selectApi)

  function fetchData() {
    const {
      minJobExperience,
      minAge,
      minEducation,
      gender,
      maxAge,
      selectedJobType,
      selectedCategory,
      districtId,
      subDistrictId,
      limit,
    } = search

    //find the sub-Categories

    if (!jobCategories || !jobCategories.data) {
      return
    }

    let matchedJobCategory = jobCategories.data.find(
      (category) => category?.id == id
    )
    setCategory(matchedJobCategory)

    let jobCategoryFilter = matchedJobCategory
      ? matchedJobCategory.sub_categories
        ? matchedJobCategory.sub_categories
            .map((subcategory) => subcategory.id)
            .join(",") +
          "," +
          matchedJobCategory.id
        : matchedJobCategory.id
      : ""

    if (!search?.selectedCategory) {
      setSubCategories(jobCategoryFilter)
      setSearch((prev) => ({
        ...prev,
        selectedCategory: jobCategoryFilter,
      }))
    }
    if (!isLoggedIn || (role !== 4 && role !== 5 && role !== 2)) {
      dispatch(
        callApi({
          operationId: UrlBuilder.resumeServiceApi(
            `resume/public/list?limit=${limit}&page=&job_exp=${
              clear ? "" : minJobExperience
            }&age_min=${clear ? "" : minAge}&age_max=${
              clear ? "" : maxAge
            }&edu_qualification_id=${clear ? "" : minEducation}&gender=${
              clear ? "" : gender
            }&job_type_id=${clear ? "" : selectedJobType}&category_ids=${
              selectedCategory ? selectedCategory : jobCategoryFilter
            }`
          ),
          output: "allResumeList",
        })
      )
    } else {
      dispatch(
        callApi({
          operationId: UrlBuilder.resumeServiceApi(
            `resume/list?limit=${limit}&page=&job_exp=${
              clear ? "" : minJobExperience
            }&district_id=${clear ? "" : districtId}&sub_district_id=${
              clear ? "" : subDistrictId
            }&age_min=${clear ? "" : minAge}&age_max=${
              clear ? "" : maxAge
            }&edu_qualification_id=${clear ? "" : minEducation}&gender=${
              clear ? "" : gender
            }&job_type_id=${clear ? "" : selectedJobType}&category_ids=${
              selectedCategory ? selectedCategory : jobCategoryFilter
            }`
          ),
          output: "allResumeList",
        })
      )
      dispatch(
        callApi({
          operationId: UrlBuilder.jobServiceApi("job/cv_request/self"),
          output: "cv_requested",
        })
      )
    }
  }

  //if there is no id then redirect it to search-cv
  useEffect(() => {
    const fetchDataAsync = async () => {
      if (jobCategories) {
        try {
          if (search.limit === 10) {
            setLoading(true)
          }
          await fetchData()
          setSearchResults(false)
        } catch (error) {
          console.error("Error fetching data:", error)
        } finally {
          // Set loading to false after a 3-second delay
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        }
      }
    }
    fetchDataAsync()
  }, [id, jobCategories])

  useEffect(() => {
    if (searchResults) {
      fetchData()
    }
  }, [searchResults])

  useEffect(() => {
    dispatch(
      callApi({
        operationId: UrlBuilder.jobServiceApi("job/education_qualifications"),
        output: "educationQualifications",
      })
    )
    dispatch(
      callApi({
        operationId: UrlBuilder.jobServiceApi("job/types"),
        output: "jobTypes",
      })
    )
    dispatch(
      callApi({
        operationId: UrlBuilder.jobServiceApi(`job/categories`),
        output: "jobCategories",
      })
    )
    dispatch(
      callApi({
        operationId: UrlBuilder.coreServiceApi(`core/location/districts`),
        output: "divisionList",
      })
    )
  }, [id])

  //..........................................................
  const isBelow1223 = useMediaQuery(`(max-width:1223px)`)
  // const isNonMobile = useMediaQuery(`(min-width:900px)`)
  const [isFilerbarOpen, setIsFilerbarOpen] = useState(true)

  const handleFilterBarOpenClose = () => {
    setIsFilerbarOpen((prev) => !prev)
  }

  // Close the filter bar when screen width is less than 900px
  useEffect(() => {
    if (isBelow1223) {
      setIsFilerbarOpen(false)
    } else {
      setIsFilerbarOpen(true) // Optionally reset when above 900px
    }
  }, [isBelow1223])

  return (
    <Box position="relative">
      {isFilerbarOpen ? (
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 100,
            bgcolor: "#F4FCF7",
            minWidth: "350px",
            display: `${isBelow1223 ? "flex" : "none"}`,
            justifyContent: "center", // Centers horizontally
            height: "calc(100vh - 80px)",
            overflowY: "scroll",
          }}
        >
          <Filter
            search={search}
            setSearch={setSearch}
            setSearchResults={setSearchResults}
            setIsFilerbarOpen={setIsFilerbarOpen}
            subCategories={subCategories}
            category={category}
          />
        </Box>
      ) : (
        <Box
          variant="outlined"
          sx={{
            top: ".5rem",
            left: "2rem",
            borderRadius: "5px",
            borderColor: "white",
            padding: "5px",
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            bgcolor: "#D9D9D9",
            position: "absolute",
            zIndex: 100,
          }}
          onClick={() => {
            handleFilterBarOpenClose()
            console.log("---open close button clicked")
          }}
        >
          <FilterAltIcon fontSize="medium" />
          <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>
            Filter
          </Typography>
        </Box>
      )}
      <Grid container width="100%">
        {isFilerbarOpen && (
          <Grid
            item
            display={isBelow1223 ? "none" : "block"}
            md={3}
            lg={3}
            xl={3}
            p={"20px"}
            // bgcolor={"red"}
            maxHeight={`calc(100vh - 80px)`}
            overflow={"auto"}
            sx={{
              WebkitOverflowScrolling: "touch",
              "&::-webkit-scrollbar": {
                width: "3px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#DADDDF",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555",
              },
              // msOverflowStyle: "none",
              //pr: 5,
            }}
          >
            <Filter
              search={search}
              setSearch={setSearch}
              setSearchResults={setSearchResults}
              setIsFilerbarOpen={setIsFilerbarOpen}
              subCategories={subCategories}
              category={category}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={`${isBelow1223 || !isFilerbarOpen ? 12 : 9}`}
          lg={`${isBelow1223 || !isFilerbarOpen ? 12 : 9}`}
          xl={`${isBelow1223 || !isFilerbarOpen ? 12 : 9}`}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {loading ? (
            <Box
              sx={{
                height: "calc(100vh - 80px)",
                Width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={60} />
              <Typography variant="h3">Loading...</Typography>
            </Box>
          ) : (
            <CvDetails
              category={category}
              setRefetch={setRefetch}
              refetch={refetch}
              setSearchResults={setSearchResults}
              search={search}
              setSearch={setSearch}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default CvDetailsNew
