import ClearIcon from "@mui/icons-material/Clear"
import SearchIcon from "@mui/icons-material/Search"
import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay"
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import InputLabel from "@mui/material/InputLabel"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { callApi, selectApi } from "store/reducers/apiSlice"
import CustomTextField from "components/form/CustomTextField"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import { UrlBuilder } from "helpers/UrlBuilder"
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import { ListSubheader } from "@mui/material"
import useAuth from "hooks/useAuth"
import { getUserRole } from "helpers/AuthUtils"
import LocationFilter from "./Job/FilterComponents/LocationFilter"
import FilterCategory from "./Job/FilterComponents/FilterCategory"
import { useTranslation } from "react-i18next"

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto #00D42A88",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}))

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#00D42A",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#00a321",
  },
})

const Filter = ({
  search,
  setSearch,
  category,
  setSearchResults,
  subCategories,
  setIsFilerbarOpen,
}) => {
  const { t, i18n } = useTranslation()
  const isLoggedIn = useAuth()
  const role = getUserRole()
  const [showSubCat, setShowSubCat] = useState(false)
  const [showLocation, setShowLocation] = useState(false)
  const { educationQualifications, jobTypes, subDistricts, divisionList } =
    useSelector(selectApi)
  const dispatch = useDispatch()
  const ageMinLimit = 16
  const ageMaxLimit = 40
  const genderList = [
    { value: "Male", name: "Male" },
    { value: "Female", name: "Female" },
  ]
  const experience = [
    { value: 1, name: "1 Year" },
    { value: 2, name: "2 Year" },
    { value: 3, name: "3 Year" },
    { value: 4, name: "4 Year" },
    { value: 5, name: "5 Year" },
    { value: 6, name: "6 Year" },
    { value: 7, name: "7 Year" },
    { value: 8, name: "8 Year" },
    { value: 9, name: "9 Year" },
    { value: 10, name: "10 Year" },
    { value: 11, name: "11 Year" },
    { value: 12, name: "12 Year" },
    { value: 13, name: "13 Year" },
    { value: 14, name: "14 Year" },
    { value: 15, name: "15 Year" },
    { value: 16, name: "16 Year" },
    { value: 17, name: "17 Year" },
    { value: 18, name: "18 Year" },
    { value: 19, name: "19 Year" },
    { value: 20, name: "20 Year" },
  ]

  useEffect(() => {
    if (search.districtId) {
      dispatch(
        callApi({
          operationId: UrlBuilder.coreServiceApi(
            `core/location/districts/${search.districtId}/sub_districts`
          ),
          output: "subDistricts",
        })
      )
    }
  }, [search.districtId])

  function isSearchInSelectedCategory(selectedCategory, number) {
    if (search?.selectedCategory == subCategories) {
      return true
    } else {
      return false
    }
  }

  const handleCategory = () => {
    console.log("categoryId", category)
    setShowSubCat(!showSubCat)
  }

  const arrayRange = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    )

  const handleCheckboxChange = (id) => {
    setSearch((prev) => ({
      ...prev,
      selectedJobType: id,
    }))
  }
  const handleCheckboxSubCategory = (id) => {
    setSearch((prevState) => ({
      ...prevState,
      selectedCategory: id,
    }))
  }
  const clearFilter = () => {
    const inputs = document.querySelectorAll("input")
    inputs.forEach((input) => (input.value = ""))
    setSearch({
      minJobExperience: "",
      minAge: "",
      minEducation: "",
      gender: "",
      maxAge: "",
      selectedJobType: "",
      districtId: "",
      subDistrictId: "",
      category: null,
      limit: 10,
      clear: false,
    })
    setSearchResults(true)
  }

  const handleCloseFilterBar = () => {
    console.log("------handle close filer clicked----")
    setIsFilerbarOpen(false)
  }

  const handleCheckboxCategory = () => {
    setSearch((prevState) => ({
      ...prevState,
      selectedCategory: subCategories,
    }))
  }

  const translateToBengaliNumber = (num) => {
    const bengaliDigits = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"]
    return num
      .toString()
      .split("")
      .map((digit) => bengaliDigits[digit])
      .join("")
  }

  return (
    <Stack
      spacing={2}
      flexDirection={"column"}
      sx={{
        height: "100%",
        maxWidth: 400,
        paddingTop: "20px",
        paddingLeft: "5px",
        paddingRight: "5px",
        alignContent: "center",

        // overflowY: "scroll",
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontWeight={300} fontSize={24} color="#444444">
          {t("app.worker.home.searchTalent")}
        </Typography>
        <Button
          color="primary"
          size="small"
          sx={{ marginLeft: 1, display: "flex", alignItems: "center" }}
          onClick={handleCloseFilterBar}
        >
          <ClearIcon fontSize="small" sx={{ marginRight: 0.5 }} />
        </Button>
      </Box>
      {showSubCat && (
        <FilterCategory
          category={category}
          subCategories={subCategories}
          search={search}
          setSearch={setSearch}
          handleCategory={handleCategory}
        />
      )}

      {!showSubCat ? (
        <>
          {!showLocation && (
            <>
              <Button
                startIcon={<DnsOutlinedIcon />}
                color="primary"
                variant="outlined"
                sx={{
                  gap: 1,
                  width: "100%",
                  height: "45px",
                }}
                onClick={() => {
                  handleCategory()
                }}
              >
                {t("app.button.selectSubCategory")}
              </Button>
              {isLoggedIn && role === 4 ? (
                <Button
                  startIcon={<RoomOutlinedIcon />}
                  color="primary"
                  variant="outlined"
                  sx={{
                    gap: 1,
                    width: "100%",
                    height: "45px",
                  }}
                  onClick={() => {
                    setShowLocation(!showLocation)
                  }}
                >
                  Select location
                </Button>
              ) : null}
            </>
          )}

          {isLoggedIn && role === 4 ? (
            <>
              {showLocation && (
                <LocationFilter
                  divisionList={divisionList}
                  setShowLocation={setShowLocation}
                  showLocation={showLocation}
                  setSearch={setSearch}
                  search={search}
                  subDistricts={subDistricts}
                />
              )}
            </>
          ) : null}
          {!showLocation && (
            <>
              <CustomTextField
                value={search?.minEducation}
                select
                inputLabel={t("app.worker.home.jobFiler.minEdu")}
                label={t("app.worker.home.jobFiler.selectEdu")}
                onChange={(e) =>
                  setSearch((prev) => ({
                    ...prev,
                    minEducation: e.target.value,
                  }))
                }
              >
                {educationQualifications?.data?.map((education) => (
                  <MenuItem key={education?.id} value={education?.id}>
                    {/* {education.name} */}
                    {i18n.language === "bn"
                      ? education?.name_bn
                      : education?.name}
                  </MenuItem>
                ))}
              </CustomTextField>
              <CustomTextField
                value={search?.minJobExperience}
                select
                inputLabel={t("app.worker.home.jobFiler.minExperience")}
                label={t("app.jobPost.selectYear")}
                sx={{ width: "100%" }}
                onChange={(e) =>
                  setSearch((prev) => ({
                    ...prev,
                    minJobExperience: e.target.value,
                  }))
                }
              >
                {experience?.map((j) => (
                  <MenuItem key={j.name} value={j.value}>
                    {/* {j.name} */}
                    {t(`app.worker.home.jobFiler.allExperince.${j.value}`)}
                  </MenuItem>
                ))}
              </CustomTextField>
              <CustomTextField
                value={search?.gender}
                select
                inputLabel={t("app.worker.profile.gender")}
                label={t("app.worker.profile.selectGender")}
                sx={{ width: "100%" }}
                onChange={(e) =>
                  setSearch((prev) => ({
                    ...prev,
                    gender: e.target.value,
                  }))
                }
              >
                {genderList?.map((g) => (
                  <MenuItem key={g.name} value={g.value}>
                    {/* {g.name} */}
                    {t(`app.worker.profile.selectGenderList.${g.name}`)}
                  </MenuItem>
                ))}
              </CustomTextField>
              <Grid container spacing={0.5}>
                <Grid item xs={12}>
                  <InputLabel
                    sx={{
                      // textTransform: "uppercase",
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#010A03",
                      fontFamily: "Nunito Sans",
                    }}
                  >
                    {t("app.worker.home.jobFiler.ageRange")}
                  </InputLabel>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    value={search?.minAge}
                    select
                    variant="outlined"
                    label={t("app.worker.profile.min")}
                    sx={{
                      width: "100%",
                      fontSize: i18n.language === "bn" ? "10px" : "12px", // Smaller font size for Bengali
                      ".MuiInputBase-input": {
                        // padding: "16px",
                        fontSize: i18n.language === "bn" ? "10px" : "12px", // Adjust input font size for Bengali
                      },
                      ".MuiInputLabel-root": {
                        fontSize: i18n.language === "bn" ? "10px" : "12px", // Adjust label font size for Bengali
                      },
                    }}
                    onChange={(e) =>
                      setSearch((prev) => ({
                        ...prev,
                        minAge: e.target.value,
                      }))
                    }
                  >
                    {arrayRange(ageMinLimit, ageMaxLimit, 1)?.map((value) => (
                      <MenuItem key={`min Age ${value}`} value={value}>
                        {/* {value} */}
                        {i18n.language === "bn"
                          ? translateToBengaliNumber(value)
                          : value}
                        {/* {t(`app.worker.home.jobFiler.ageRangeList.${value}`)} */}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    value={search?.maxAge}
                    select
                    variant="outlined"
                    label={t("app.worker.profile.max")}
                    sx={{
                      width: "100%",
                      fontSize: i18n.language === "bn" ? "10px" : "12px", // Smaller font size for Bengali
                      ".MuiInputBase-input": {
                        // padding: "16px",
                        fontSize: i18n.language === "bn" ? "10px" : "12px", // Adjust input font size for Bengali
                      },
                      ".MuiInputLabel-root": {
                        fontSize: i18n.language === "bn" ? "10px" : "12px", // Adjust label font size for Bengali
                      },
                    }}
                    onChange={(e) =>
                      setSearch((prev) => ({
                        ...prev,
                        maxAge: e.target.value,
                      }))
                    }
                  >
                    {search?.minAge != "" &&
                      arrayRange(search?.minAge + 1, ageMaxLimit + 5, 1)?.map(
                        (value) => (
                          <MenuItem key={`max Age ${value}`} value={value}>
                            {/* {value} */}
                            {i18n.language === "bn"
                              ? translateToBengaliNumber(value)
                              : value}
                          </MenuItem>
                        )
                      )}
                  </TextField>
                </Grid>
              </Grid>
              {/* JOB TYPE */}
              <Grid container item sx={{ paddingLeft: "8px" }}>
                <Grid item xs={10}>
                  <InputLabel
                    sx={{
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#010A03",
                      fontFamily: "Nunito Sans",
                    }}
                  >
                    {t("app.jobPost.jobtype")}
                  </InputLabel>
                </Grid>

                <Grid item xs={6}>
                  <FormGroup>
                    {jobTypes?.data?.slice(0, 2).map((g) => (
                      <FormControlLabel
                        key={`jobTypes - ${g.id}`}
                        control={
                          <Checkbox
                            checked={search?.selectedJobType === g.id}
                            onChange={() => handleCheckboxChange(g.id)}
                            sx={{
                              "&:hover": { bgcolor: "transparent" },
                            }}
                            disableRipple
                            color="default"
                            checkedIcon={<BpCheckedIcon />}
                            icon={<BpIcon />}
                            inputProps={{ "aria-label": "Checkbox demo" }}
                          />
                        }
                        // label={g.name}
                        label={i18n.language === "bn" ? g?.name_bn : g?.name}
                      />
                    ))}
                  </FormGroup>
                </Grid>

                <Grid item xs={6}>
                  <FormGroup>
                    {jobTypes?.data?.slice(2, 4).map((g) => (
                      <FormControlLabel
                        key={`jobTypes - ${g.id}`}
                        control={
                          <Checkbox
                            checked={search?.selectedJobType === g.id}
                            onChange={() => handleCheckboxChange(g.id)}
                            sx={{
                              "&:hover": { bgcolor: "transparent" },
                            }}
                            disableRipple
                            color="default"
                            checkedIcon={<BpCheckedIcon />}
                            icon={<BpIcon />}
                            inputProps={{ "aria-label": "Checkbox demo" }}
                          />
                        }
                        // label={g.name}
                        label={i18n.language === "bn" ? g?.name_bn : g?.name}
                      />
                    ))}
                  </FormGroup>
                </Grid>
              </Grid>

              <Button
                startIcon={<SearchIcon />}
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() => setSearchResults(true)}
              >
                {t("app.search")}
              </Button>
              <Button
                startIcon={<ClearIcon />}
                color="secondary"
                variant="contained"
                sx={{ width: "100%" }}
                onClick={clearFilter}
              >
                {t("app.worker.home.jobFiler.clearFilter")}
              </Button>
            </>
          )}
        </>
      ) : null}
    </Stack>
  )
}

export default Filter
