import { lazy } from "react";
import Loadable from "components/Loadable";
import Company from "../pages/company/company";
import RequestedCvScreen from "pages/employer/dashboard/RequestedCvScreen";
import CvDetails from "pages/search/Cv/CvDetails";
import CvDetailsNew from "pages/search/Cv/CvDetailsNew";
import JobList from "pages/search/Job/JobList";

const CommonLayout = Loadable(lazy(() => import("layout/CommonLayout")));
const Portal = Loadable(lazy(() => import("pages/portal/Portal")));
const CvSearch = Loadable(lazy(() => import("pages/search/Cv/CvSearch")));
const JobSearch = Loadable(lazy(() => import("pages/search/Job/JobSearch")));
const AuthLogin = Loadable(lazy(() => import("pages/auth/login")));
const ForgotPassword = Loadable(
  lazy(() => import("pages/auth/ForgotPassword"))
);
const SettingsPage = Loadable(
  lazy(() => import("pages/Settings-and-Privacy/SettingsPage"))
);
const AuthRegistration = Loadable(
  lazy(() => import("pages/auth/registration"))
);
const Error404 = Loadable(lazy(() => import("pages/maintenance/404")));
const PrivacyPolicy = Loadable(lazy(() => import("pages/company/company")));

const PublicRoutes = {
  path: "/",
  element: <CommonLayout layout="simple" />,
  children: [
    {
      path: "/",
      element: <Portal />,
    },
    {
      path: "search-cv",
      element: <CvSearch />,
    },
    {
      path: "search-cv/cv-list",
      element: <CvDetailsNew />,
    },

    {
      path: "search-job",
      element: <JobSearch />,
    },
    {
      path: "/search-job/job-list",
      element: <JobList />,
    },

    {
      path: "login",
      element: <AuthLogin />,
    },
    {
      path: "forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "registration",
      element: <AuthRegistration />,
    },
    {
      path: "settings",
      element: <SettingsPage />,
    },
    {
      path: "*",
      element: <Error404 />,
    },
    // {
    //   path: "home",
    //   element: <MainPage />,
    // },
    // {
    //   path: "cv-requests",
    //   element: <RequestedCvScreen />,
    // },
  ],
};

export default PublicRoutes;
