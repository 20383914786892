import React, { useEffect, useState } from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import axios from "axios"
import { UrlBuilder } from "helpers/UrlBuilder"
import portalImage from "assets/images/portal/mockup.jpg"
import { makeStyles, useTheme } from "@mui/styles"
import { selectApi, callApi } from "store/reducers/apiSlice"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import useAuth from "hooks/useAuth"
import { getAccessToken } from "helpers/AuthUtils"
import { CvListSimpleBar } from "components/CVSearch/CvListSimpleBar"
import { useTranslation } from "react-i18next"

// import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"

import useMediaQuery from "@mui/material/useMediaQuery"

import CvDrawer from "components/CVSearch/CvDrawer"

const useStyles = makeStyles((theme) => ({
  nameContainer: {
    display: "flex",
    // alignItems: "center",

    width: "100%",
    Padding: "1.4rem",
    // flex: 2,
    // gap: {
    //   xs: "10px",
    //   md: "20px",
    // },
    gap: "1rem",
    marginBottom: "20px",
  },
  iconContainer: {
    borderRadius: "50px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "3.81rem",
    height: "3.37rem",
  },
  icon: {
    fontSize: "40px", // Adjust the icon size as needed
    color: "#6BD425", // Use the specified icon color
  },
  textContainer: {
    color: "#000000",
  },
  favoritesContainer: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  favorites: {
    textAlign: "left",
  },
}))

const CvDetails = ({
  category,
  setRefetch,
  refetch,
  setSearchResults,
  search,
  setSearch,
}) => {
  const { t, i18n } = useTranslation()
  const [showAllCategories, setShowAllCategories] = useState(false)
  const [moreCvDetails, setMoreCvDetails] = useState("Experience")
  const [selectCvIndex, setSelectCvIndex] = useState(0)
  const [openDrawer, setOpenDrawer] = useState(false)
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [message, setMessage] = useState("")

  const isLoggedIn = useAuth()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const {
    educationQualifications,
    allResumeList,
    jobCategories,
    jobTypes,
    cv_requested,
  } = useSelector(selectApi)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  // ------------------------match and filter cv for find out status= panding or not ----------------------------------
  const filteredCVs = (() => {
    if (allResumeList?.data?.resumes?.length > 0) {
      return cv_requested?.data?.job_request?.filter(
        (cv) =>
          cv.worker_user_id ===
          allResumeList.data.resumes[selectCvIndex]?.user_id
      )
    }
    return null
  })()

  // ----------------------------------Worker Job Categories find----------------------------------
  // const findCategoriesName = (id) => {
  //   let categories
  //   jobCategories?.data.forEach((c) => {
  //     if (c.sub_categories) {
  //       c.sub_categories.forEach((sc) => {
  //         if (sc.id == id) {
  //           categories = sc
  //         }
  //       })
  //       if (c.id == id) {
  //         categories = c
  //       }
  //     } else if (c.id == id) {
  //       categories = c
  //     }
  //   })
  //   return categories ? categories.name : ""
  // }
  const findCategoriesName = (id) => {
    let categories

    jobCategories?.data.forEach((c) => {
      if (c.sub_categories) {
        c.sub_categories.forEach((sc) => {
          if (sc.id == id) {
            categories = sc // Found the sub-category
          }
        })
        if (c.id == id) {
          categories = c // Found the main category
        }
      } else if (c.id == id) {
        categories = c // Found the main category without sub-categories
      }
    })

    // Return the Bengali name if found, otherwise return the English name
    return categories
      ? i18n.language === "bn"
        ? categories.name_bn
        : categories.name
      : ""
  }

  // ---------------------------------find educational name------------------------
  const findEducation = (id) => {
    let categories
    educationQualifications?.data.forEach((c) => {
      if (c.sub_categories) {
        c.sub_categories.forEach((sc) => {
          if (sc.id == id) {
            categories = sc
          }
        })
        if (c.id == id) {
          categories = c
        }
      } else if (c.id == id) {
        categories = c
      }
    })
    if (categories) {
      return categories.name.trim() === "No Academic Qualification"
        ? "Education: N/A"
        : categories.name
    }
    return ""
  }

  //   ------------------------------Handle CV Request Modal open----------------------------------
  const handleCVRequest = () => {
    if (!isLoggedIn) {
      navigate("/login")
    } else {
      handleOpen()
    }
  }

  // ------------------------------Send Request Function for modal---------------------------
  const handleSendRequest = async (event) => {
    console.log("send request")

    const data = {
      worker_user_id: allResumeList?.data?.resumes[selectCvIndex]?.user_id,
      message: message,
    }
    try {
      const res = await axios.post(
        UrlBuilder.jobServiceApi("job/cv_request"),
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`, // Include the access token in the Authorization header
          },
        }
      )

      console.log("Success:", res.data)
      setRefetch(true)
    } catch (error) {
      console.error("Error:", error)
      alert("Error: Already Requested")
    }
    handleClose()
    setMessage("")
  }

  const handleCardClick = (index) => {
    setSelectCvIndex(index)
    setOpenDrawer(true)
    console.log("button clicked", index)
  }
  const resumeCount = allResumeList?.data?.resumes?.length
  const convertToBengaliDigits = (number) => {
    const bengaliDigits = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"]
    return String(number)
      .split("")
      .map((digit) => bengaliDigits[digit] || digit)
      .join("")
  }
  return (
    <Box>
      <Grid container>
        <Grid
          id="scrollableDiv"
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            paddingLeft: "2rem",
            paddingRight: "2rem",
            paddingBottom: 0,
            backgroundColor: "primary.lighter",
            height: "calc(100vh - 80px)",
            overflowY: "scroll",
            position: "relative",
          }}
          // style={{ height: window.innerHeight - 96 }}
        >
          <Box
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 10,
              backgroundColor: "#e3ecea",
              paddingTop: 5,
            }}
          >
            <Typography
              fontWeight={300}
              fontSize={isMobile ? "2rem" : "2.625rem"}
              color="#444444"
            >
              {t("app.worker.cv.resumeCount", {
                count:
                  i18n.language === "bn"
                    ? convertToBengaliDigits(resumeCount)
                    : resumeCount,
              })}{" "}
              {t("app.worker.cv.cvName")}{" "}
              {category ? findCategoriesName(category.id) : "All"}
            </Typography>
            <Typography variant="subtitle1" gutterBottom color="secondary.main">
              from{" "}
              {search.subDistrictId !== "" || null
                ? `${search.subDistrictName}, `
                : ""}{" "}
              {search.districtId !== "" || null
                ? `${search.districtName}`
                : "all"}
            </Typography>
          </Box>
          {/* ------------------------CV Lists-------------------------------------- */}

          <CvListSimpleBar
            setSearchResults={setSearchResults}
            search={search}
            setSearch={setSearch}
            data={allResumeList?.data?.resumes}
            showAllCategories={showAllCategories}
            //setSelectCvIndex={setSelectCvIndex}
            handleCardClick={handleCardClick}
            findCategoriesName={findCategoriesName}
            findEducation={findEducation}
            selectCvIndex={selectCvIndex}
          />
        </Grid>
        {/* ------------------------------------CV Details--------------------------------------------- */}
        <Grid item width={"100%"} xs={12} sm={12} md={6} lg={6}>
          <CvDrawer
            setRefetch={setRefetch}
            refetch={refetch}
            isMobile={isMobile}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            allResumeList={allResumeList}
            isLoggedIn={isLoggedIn}
            portalImage={portalImage}
            selectCvIndex={selectCvIndex}
            classes={classes}
            findCategoriesName={findCategoriesName}
            filteredCVs={filteredCVs}
            handleCVRequest={handleCVRequest}
            open={open}
            handleClose={handleClose}
            message={message}
            setMessage={setMessage}
            handleSendRequest={handleSendRequest}
            jobTypes={jobTypes}
            educationQualifications={educationQualifications}
            moreCvDetails={moreCvDetails}
            setMoreCvDetails={setMoreCvDetails}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default CvDetails
