import { Box, Button, ListSubheader, MenuItem, Typography } from "@mui/material"
import React from "react"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import CustomTextField from "components/form/CustomTextField"
import { useTranslation } from "react-i18next"

function LocationFilter({
  divisionList,
  setShowLocation,
  showLocation,
  setSearch,
  search,
  subDistricts,
}) {
  const { t, i18n } = useTranslation()
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: "100%",
      }}
    >
      <Button
        sx={{
          gap: 1,
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: 0,
        }}
        onClick={() => {
          setShowLocation(!showLocation)
        }}
      >
        <ArrowBackIosNewIcon />
        <Typography fontSize={21} fontWeight={700}>
          {t("app.worker.home.jobFiler.selectLocation")}
        </Typography>
      </Button>
      <CustomTextField
        select
        label={t("app.workerPersonalInfo.districtPlaceholderText")}
        sx={{ width: "100%" }}
        value={search.districtId}
        onChange={(e) => {
          const selectedDistrict = divisionList?.data
            .flatMap((division) => division.districts)
            .find((district) => district.id === e.target.value)

          setSearch((prev) => ({
            ...prev,
            districtId: e.target.value,
            districtName: selectedDistrict?.name || "",
            subDistrictId: "",
          }))
        }}
        inputLabel={
          <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
            {t("app.workerPersonalInfo.districtLabelText")}
          </Typography>
        }
      >
        {divisionList?.data?.map((division) => [
          <ListSubheader
            key={division.id}
            sx={{ borderBottom: "1px solid #ccc" }}
          >
            <Typography sx={{ fontSize: "1rem", fontWeight: 700 }}>
              {/* {division.name} */}
              {i18n.language === "bn" ? division?.name_bn : division?.name}
            </Typography>
          </ListSubheader>,
          ...division.districts?.map((district) => (
            <MenuItem
              key={district.id}
              value={district.id}
              sx={{
                bgcolor:
                  search.districtId === district.id ? "green" : "inherit",
                color: search.districtId === district.id ? "white" : "inherit",
                "&:hover": {
                  bgcolor: "#00D42A",
                  color: "white",
                },
                "&:focus": {
                  bgcolor: "#00D42A",
                  color: "white",
                },
                paddingLeft: "30px",
                fontWeight: "700",
                py: "10px",
              }}
            >
              {/* {district.name} */}
              {i18n.language === "bn" ? district?.name_bn : district?.name}
            </MenuItem>
          )),
        ])}
      </CustomTextField>

      <CustomTextField
        disabled={search.districtId === ""}
        select
        inputLabel={
          <Typography sx={{ fontSize: "1", fontWeight: 700 }}>
            {t("app.worker.home.jobFiler.selectSubDistrict")}
          </Typography>
        }
        label={t("app.workerPersonalInfo.upozillatPlaceholderText")}
        sx={{ width: "100%" }}
        value={search.subDistrictId}
        onChange={(e) => {
          const selectedSubDistrict = subDistricts?.data?.find(
            (subDistrict) => subDistrict.id === e.target.value
          )

          setSearch((prev) => ({
            ...prev,
            subDistrictId: e.target.value,
            subDistrictName: selectedSubDistrict?.name || "",
          }))
        }}
      >
        {subDistricts?.data?.map((j) => (
          <MenuItem
            key={j.id}
            value={j.id}
            sx={{
              bgcolor: search.subDistrictId === j.id ? "green" : "inherit",
              color: search.subDistrictId === j.id ? "white" : "inherit",
              "&:hover": {
                bgcolor: "#00D42A",
                color: "white",
              },
              "&:focus": {
                bgcolor: "#00D42A",
                color: "white",
              },
              paddingLeft: "30px",
              fontWeight: "700",
              py: "10px",
            }}
          >
            {/* {j.name} */}
            {i18n.language === "bn" ? j?.name_bn : j?.name}
          </MenuItem>
        ))}
      </CustomTextField>

      <Button
        style={{ width: "100%" }}
        variant="contained"
        onClick={() => {
          setShowLocation(!showLocation)
        }}
      >
        {/* SET LOCATION */}
        {t("app.worker.home.jobFiler.setLocation")}
      </Button>
    </Box>
  )
}

export default LocationFilter
