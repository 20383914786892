import React, { useEffect, useState } from "react"
import { Box, Button, Drawer, Grid, Typography } from "@mui/material"
import HomeIcon from "@mui/icons-material/Home"
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded"
import SimpleBar from "simplebar-react"
import { NameSplitting } from "components/CVSearch/NameSplitting"
import { CertificateCard } from "components/CVSearch/CertificateCard"
import ExperienceCard from "components/CVSearch/ExperienceCard"
import EducationCard from "components/CVSearch/EducationCard"
import CvRequestModal from "components/CVSearch/CvRequestModal"
import Lock from "../../assets/lock.gif"
import { useNavigate } from "react-router-dom"
import DetailsMenu from "./DetailsMenu"
import { getUserRole } from "helpers/AuthUtils"
import { useDispatch, useSelector } from "react-redux"
import { callApi, selectApi } from "store/reducers/apiSlice"
import { UrlBuilder } from "helpers/UrlBuilder"

const CvDrawer = ({
  isMobile,
  openDrawer,
  setOpenDrawer,
  allResumeList,
  isLoggedIn,
  portalImage,
  selectCvIndex,
  classes,
  findCategoriesName,
  filteredCVs,
  handleCVRequest,
  open,
  handleClose,
  message,
  setMessage,
  handleSendRequest,
  refetch,
  jobTypes,
  educationQualifications,
  moreCvDetails,
  setMoreCvDetails,
}) => {
  const navigate = useNavigate()
  const role = getUserRole()
  const { jobCategories, is_cv_requested } = useSelector(selectApi)
  const dispatch = useDispatch()

  useEffect(() => {
    if (allResumeList?.data?.resumes?.length > 0) {
      dispatch(
        callApi({
          operationId: UrlBuilder.jobServiceApi(
            `job/cv_request/self?worker_user_id=${allResumeList?.data?.resumes[selectCvIndex]?.user_id}`
          ),
          output: "is_cv_requested",
        })
      )
    }
  }, [selectCvIndex, refetch])

  console.log("is_cv_requested", is_cv_requested)

  const renderProfileDetails = () => (
    <Grid
      sx={{
        //paddingLeft: "2.5rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingBottom: 0,
        position: "relative",
        height: openDrawer ? "100%" : "calc(100vh - 80px)",
        overflowY: "auto",
        backgroundColor: "#fafafb",
      }}
    >
      {!isLoggedIn || (role !== 4 && role !== 5 && role !== 2) ? (
        <Box
          sx={{
            position: "absolute",
            zIndex: 10,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            navigate("/login")
          }}
        >
          <Box component={"img"} src={Lock} />
          <Typography
            mb={1}
            variant="h4"
            component="h2"
            sx={{ whiteSpace: "nowrap" }}
          >
            Please Login to unlock
          </Typography>
        </Box>
      ) : null}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "#fafafb",
          paddingTop: "2.5rem",
          pb: "10px",
          zIndex: 10,
        }}
      >
        <Box className={classes.nameContainer}>
          <Box className={classes.iconContainer}>
            {portalImage ? (
              <Box
                component="img"
                src={portalImage}
                alt="Preview"
                sx={{ width: "100%", height: "100%" }}
              />
            ) : (
              <HomeIcon fontSize="large" style={{ fontSize: "50px" }} />
            )}
          </Box>
          <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
            <Box className={classes.textContainer}>
              {!isLoggedIn || (role !== 4 && role !== 5 && role !== 2) ? (
                <NameSplitting
                  fullName={
                    allResumeList?.data?.resumes[selectCvIndex]?.full_name
                  }
                />
              ) : (
                <Box>
                  <Box gap={"10px"}>
                    <Typography
                      variant="h4"
                      className={classes.heading}
                      sx={{
                        fontSize: isMobile ? "1.3rem" : "1.688rem",
                        textWrap: "nowrap",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {allResumeList?.data?.resumes[selectCvIndex]?.fullname}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#696969",
                        fontSize: isMobile ? "0.875rem" : "1.125rem",
                      }}
                    >
                      {findCategoriesName(
                        allResumeList?.data?.resumes[selectCvIndex]?.category_id
                      )}
                    </Typography>
                    <Button
                      sx={{
                        padding: "4px 8px",
                        backgroundColor: "#00D42A",
                        color: "#FFFFFF",
                      }}
                      startIcon={<CheckCircleRoundedIcon />}
                    >
                      Available
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
            <Box>
              {(isLoggedIn && role === 4) || role === 2 ? (
                <>
                  {is_cv_requested?.data?.job_request?.length > 0 ? (
                    <Button
                      style={{
                        padding: "4px 8px",
                        textTransform: "uppercase",
                        marginTop: "8px",
                      }}
                      variant="contained"
                    >
                      {is_cv_requested?.data?.job_request[0]?.status}
                    </Button>
                  ) : (
                    <Button
                      style={{
                        padding: "4px 8px",
                        textTransform: "uppercase",
                        marginTop: "8px",
                      }}
                      variant="contained"
                      onClick={handleCVRequest}
                    >
                      REQUEST CONTACT
                    </Button>
                  )}
                </>
              ) : null}
              <CvRequestModal
                open={open}
                handleClose={handleClose}
                message={message}
                setMessage={setMessage}
                handleSendRequest={handleSendRequest}
              />
            </Box>
          </Box>
        </Box>
        <DetailsMenu
          moreCvDetails={moreCvDetails}
          setMoreCvDetails={setMoreCvDetails}
        />
      </Box>
      <Grid container spacing={4} mt={2}>
        <Grid item xs={12}>
          {isLoggedIn && (role === 4 || role === 5 || role === 2) ? (
            <Box>
              {moreCvDetails === "Experience" && (
                <Box>
                  <Typography fontSize={"17px"} fontWeight={400} mb={1}>
                    Experience
                  </Typography>
                  <Box style={{ pl: 1, pr: 3 }}>
                    {allResumeList?.data?.resumes[
                      selectCvIndex
                    ]?.experiences?.map((item, index) => (
                      <Grid item key={`Experience - ${index}`} xs={12}>
                        <ExperienceCard
                          data={item}
                          jobTypes={jobTypes}
                          role={role}
                        />
                      </Grid>
                    ))}
                  </Box>
                </Box>
              )}
              {moreCvDetails === "Education" && (
                <Box>
                  <Typography fontSize={"17px"} fontWeight={400} mb={1}>
                    Education
                  </Typography>
                  <Box style={{ pl: 1, pr: 3 }}>
                    {allResumeList?.data?.resumes[
                      selectCvIndex
                    ]?.experiences?.map((item, index) => (
                      <Grid item key={`Education - ${index}`} xs={12}>
                        <EducationCard
                          data={item}
                          educationQualifications={educationQualifications}
                          role={role}
                        />
                      </Grid>
                    ))}
                  </Box>
                </Box>
              )}
              {moreCvDetails === "Certificate" && (
                <Box>
                  <Typography fontSize={"17px"} fontWeight={400} mb={1}>
                    License/Certificate
                  </Typography>
                  <Box style={{ pl: 1, pr: 3 }}>
                    {allResumeList?.data?.resumes[
                      selectCvIndex
                    ]?.experiences?.map((item, index) => (
                      <Grid item key={`Certificate - ${index}`} xs={12}>
                        <CertificateCard data={item} role={role} />
                      </Grid>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <Box sx={{ filter: "blur(5px)" }}>
              {moreCvDetails === "Experience" && (
                <Box>
                  <Typography fontSize={"17px"} fontWeight={400} mb={1}>
                    Experience
                  </Typography>
                  <Box style={{ pl: 1, pr: 3 }}>
                    {allResumeList?.data?.resumes[
                      selectCvIndex
                    ]?.experiences?.map((item, index) => (
                      <Grid item key={`Experience - ${index}`} xs={12}>
                        <ExperienceCard data={item} />
                      </Grid>
                    ))}
                  </Box>
                </Box>
              )}
              {moreCvDetails === "Education" && (
                <Box>
                  <Typography fontSize={"17px"} fontWeight={400} mb={1}>
                    Education
                  </Typography>
                  <Box style={{ pl: 1, pr: 3 }}>
                    {allResumeList?.data?.resumes[
                      selectCvIndex
                    ]?.experiences?.map((item, index) => (
                      <Grid item key={`Education - ${index}`} xs={12}>
                        <EducationCard data={item} />
                      </Grid>
                    ))}
                  </Box>
                </Box>
              )}
              {moreCvDetails === "Certificate" && (
                <Box>
                  <Typography fontSize={"17px"} fontWeight={400} mb={1}>
                    License/Certificate
                  </Typography>
                  <Box style={{ pl: 1, pr: 3 }}>
                    {allResumeList?.data?.resumes[
                      selectCvIndex
                    ]?.experiences?.map((item, index) => (
                      <Grid item key={`Certificate - ${index}`} xs={12}>
                        <CertificateCard data={item} />
                      </Grid>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  )

  return isMobile ? (
    <Drawer
      open={openDrawer}
      anchor="right"
      onClose={() => setOpenDrawer(false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "80%",
          height: "100vh", // Set the height to 100vh when the drawer is open
        },
      }}
    >
      {!allResumeList?.data?.resumes?.length > 0 ? (
        <Grid item sx={{ padding: 5 }}></Grid>
      ) : (
        renderProfileDetails()
      )}
    </Drawer>
  ) : (
    <>
      {!allResumeList?.data?.resumes?.length > 0 ? (
        <Grid item sx={{ padding: 5 }}></Grid>
      ) : (
        renderProfileDetails()
      )}
    </>
  )
}

export default CvDrawer
