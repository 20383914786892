import { Box, Button, Checkbox, FormGroup, Typography } from "@mui/material"
import React from "react"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import { styled } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

const BpIcon = styled("span")(() => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow: "0 0 0 1.5px gray",
}))

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#00D42A",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#00a321",
  },
})

function FilterCategory({
  category,
  subCategories,
  search,
  setSearch,
  handleCategory,
}) {
  const { t, i18n } = useTranslation()
  function isSearchInSelectedCategory(selectedCategory, number) {
    if (search?.selectedCategory == subCategories) {
      return true
    } else {
      return false
    }
  }
  const handleCheckboxCategory = () => {
    setSearch((prevState) => ({
      ...prevState,
      selectedCategory: subCategories,
    }))
  }

  const handleCheckboxSubCategory = (id) => {
    setSearch((prevState) => ({
      ...prevState,
      selectedCategory: id,
    }))
  }
  return (
    <>
      <Button
        sx={{
          gap: 1,
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: 0,
        }}
        onClick={() => {
          handleCategory()
        }}
      >
        <ArrowBackIosNewIcon />
        <Typography fontSize={21} fontWeight={700}>
          {/* Select Category */}
          {t("app.button.selectSubCategory")}
        </Typography>
      </Button>
      <div
        style={{
          backgroundColor: "#EAEAEA",
          height: "1px",
          width: "100%",
          margin: "10px 0px",
        }}
      ></div>
      <FormGroup row md={6}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography style={{ fontSize: "21px", fontWeight: 700 }}>
            {/* {category.name} */}
            {i18n.language === "bn" ? category?.name_bn : category.name}
          </Typography>
          <Checkbox
            checked={isSearchInSelectedCategory(
              subCategories,
              search?.selectedCategory
            )}
            onChange={() => handleCheckboxCategory(category.id)}
            sx={{ backgroundColor: "transparent" }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ "aria-label": "Checkbox demo" }}
          />
        </Box>

        {category?.sub_categories.map((sub) => (
          <React.Fragment key={`jobTypes - ${sub.id}`}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography sx={{ color: "black", fontSize: "14px" }}>
                {/* {sub.name} */}
                {i18n.language === "bn" ? sub?.name_bn : sub?.name}
              </Typography>
              <Checkbox
                checked={
                  search?.selectedCategory == subCategories
                    ? [subCategories].includes(search?.selectedCategory)
                    : search?.selectedCategory === sub?.id
                }
                onChange={() => handleCheckboxSubCategory(sub?.id)}
                sx={{ "&:hover": { bgcolor: "transparent" } }}
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                inputProps={{ "aria-label": "Checkbox demo" }}
              />
            </Box>
          </React.Fragment>
        ))}
      </FormGroup>
    </>
  )
}

export default FilterCategory
