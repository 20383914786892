import React, { useState } from "react";
import { Drawer, Grid, Box, Typography, Button, Stack, Divider } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import HomeIcon from "@mui/icons-material/Home";
import SimpleBar from "../../../components/third-party/SimpleBar";
import ExperienceCard from "components/CVSearch/ExperienceCard";
import EducationCard from "components/CVSearch/EducationCard";
import { CertificateCard } from "components/CVSearch/CertificateCard";
import { useNavigate } from "react-router";

const RequestedCvScreenDrwaer = ({
  isMobile,
  openDrawer,
  setOpenDrawer,
  singleResume,
  portalImage,
  findCategoriesName,
  classes,
  jobTypes,
  educationQualifications,
}) => {
  const navigate=useNavigate();
  const [moreCvDetails, setMoreCvDetails] = useState("Experience");
  const DetailsMenu = () => {
    return (
      <Stack direction="row" spacing={2}>
        <Button
          onClick={() => setMoreCvDetails("Experience")}
          sx={
            moreCvDetails === "Experience"
              ? {
                  fontStyle: "bolt",
                  borderBottom: 2,
                  borderRadius: 0,
                  fontSize: isMobile ? "0.75rem" : "1.063rem",
                  fontWeight: 600,
                  color: "#355E79",
                  backgroundColor: "transparent",
                }
              : {
                  color: "#A7A7A7",
                  fontSize: isMobile ? "0.75rem" : "1.063rem",
                  fontWeight: 400,
                  backgroundColor: "transparent",
                }
          }
        >
          Experience
        </Button>
        <Button
          onClick={() => setMoreCvDetails("Education")}
          sx={
            moreCvDetails === "Education"
              ? {
                  fontStyle: "bolt",
                  borderBottom: 2,
                  borderRadius: 0,
                  fontSize: isMobile ? "0.75rem" : "1.063rem",
                  fontWeight: 600,
                  color: "#355E79",
                  backgroundColor: "transparent",
                }
              : {
                  color: "#A7A7A7",
                  fontWeight: 400,
                  fontSize: isMobile ? "0.75rem" : "1.063rem",
                  backgroundColor: "transparent",
                }
          }
        >
          Education
        </Button>
        <Button
          onClick={() => setMoreCvDetails("Certificate")}
          sx={
            moreCvDetails === "Certificate"
              ? {
                  fontStyle: "bolt",
                  borderBottom: 2,
                  borderRadius: 0,
                  fontSize: isMobile ? "0.75rem" : "1.063rem",
                  fontWeight: 600,
                  color: "#355E79",
                  backgroundColor: "transparent",
                }
              : {
                  color: "#A7A7A7",
                  fontWeight: 400,
                  fontSize: isMobile ? "0.75rem" : "1.063rem",
                  backgroundColor: "transparent",
                }
          }
        >
          License/ Certificate
        </Button>
      </Stack>
    );
  };

  const renderCvDetails = () => (
    <Grid
      item
      md={4}
      sm={12}
      sx={{
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingBottom: 0,
        position: "relative",
        height: isMobile ? "100%" : "calc(100vh - 80px)",
        overflowY: "auto",
        backgroundColor: "#fafafb",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "#fafafb",
          paddingTop: "2.5rem",
          pb: "10px",
          zIndex: 1,
        }}
      >
        <Box className={classes.nameContainer}>
          <Box className={classes.iconContainer}>
            {portalImage ? (
              <img
                src={portalImage}
                alt="Preview"
                style={{ width: "100px", height: "100px" }}
              />
            ) : (
              <HomeIcon fontSize="large" className={classes.icon} />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "13px",
              justifyContent: "flex-start",
            }}
          >
            <Box className={classes.textContainer} ml={2}>
              <Box display={"flex"} gap={"10px"} sx={{ alignItems: "center" }}>
                <Typography
                  variant="h4"
                  className={classes.heading}
                  //style={{ fontSize: 27 }}
                  //sx={{ fontSize: isMobile ? "20px" : "27px" }}
                  sx={{
                    fontSize: {
                      xl: "1.688rem",
                      lg: "1.688rem",
                      md: "1.563rem",
                      sm: "1.375rem",
                      xs: "1.25rem",
                    },
                  }}
                >
                  {singleResume?.data?.fullname}
                </Typography>
                {singleResume?.data?.active && (
                  <Button
                    sx={{
                      // padding: isMobile ? "0.313rem" : "0.5rem",
                      backgroundColor: "#00D42A",
                      color: "#FFFFFF",
                      // height: isMobile ? "1.25rem" : "1.625rem",
                      //fontSize: isMobile ? "0.75rem" : "1rem",
                      // width: isMobile ? "auto" : "100%",
                      fontSize: {
                        xl: "1rem",
                        lg: "1rem",
                        md: "0.875rem",
                        sm: "0.813rem",
                        xs: "0.75rem",
                      },

                      padding: {
                        xl: "0.5rem",
                        lg: "0.5rem",
                        md: "0.438rem",
                        sm: "0.375rem",
                        xs: "0.313",
                      },
                      //width: "100px",
                    }}
                    startIcon={<CheckCircleRoundedIcon sx={{}} />}
                  >
                    Available
                  </Button>
                )}
              </Box>
              <Typography
                variant="body1"
                sx={{
                  color: "#696969",
                  //fontSize: isMobile ? "14px" : "18px",
                  fontSize: {
                    xl: "1.125rem",
                    lg: "1.125rem",
                    md: "1rem",
                    sm: "0.938rem",
                    xs: "0.875rem",
                  },
                }}
              >
                {findCategoriesName(singleResume?.data?.category_id)}
              </Typography>
              <Button variant="contained" color="primary" size="small" onClick={()=>navigate("/chat")}>Contact Now</Button>
            </Box>
           
          </Box>
        </Box>
        <Divider sx={{ borderBottomWidth: "2px", borderColor: "#69696980" }}/>
        <DetailsMenu />
      </Box>

      <Grid container spacing={4} mt={2}>
        <Grid item xs={12}>
          <Box>
            {moreCvDetails === "Experience" && (
              <Box>
                <Typography fontSize={"1.063rem"} fontWeight={400} mb={1}>
                  Experience
                </Typography>
                <Box style={{ pl: 1, pr: 3 }}>
                  {singleResume?.data?.experiences
                    ?.filter((item) => item.type === 1) // Filter by type: 1
                    .map((item, index) => (
                      <Grid item key={`Experience - ${index}`} xs={12}>
                        <ExperienceCard data={item} jobTypes={jobTypes} />
                      </Grid>
                    ))}
                </Box>
              </Box>
            )}
            {moreCvDetails === "Education" && (
              <Box>
                <Typography fontSize={"1.063rem"} fontWeight={400} mb={1}>
                  Education
                </Typography>
                <Box style={{ pl: 1, pr: 3 }}>
                  {singleResume?.data?.experiences
                    ?.filter((item) => item.type === 1) // Filter by type: 1
                    .map((item, index) => (
                      <Grid item key={`Education - ${index}`} xs={12}>
                        <EducationCard
                          data={item}
                          educationQualifications={educationQualifications}
                        />
                      </Grid>
                    ))}
                </Box>
              </Box>
            )}
            {moreCvDetails === "Certificate" && (
              <Box>
                <Typography fontSize={"1.063rem"} fontWeight={400} mb={1}>
                  License/Certificate
                </Typography>
                <Box style={{ pl: 1, pr: 3 }}>
                  {singleResume?.data?.experiences
                    ?.filter((item) => item.type === 2) // Filter by type: 1
                    .map((item, index) => (
                      <Grid item key={`Certificate - ${index}`} xs={12}>
                        <CertificateCard data={item} />
                      </Grid>
                    ))}
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <Drawer
      open={openDrawer}
      anchor="right"
      onClose={() => setOpenDrawer(false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "80%",
          height: "100%",
        },
      }}
    >
      {singleResume?.data?.resumes?.length === 0 ? (
        <Grid item md={6} sm={12} sx={{ padding: 5 }}></Grid>
      ) : (
        renderCvDetails()
      )}
    </Drawer>
  ) : (
    <>
      {singleResume?.data?.resumes?.length === 0 ? (
        <Grid item md={6} sm={12} sx={{ padding: 5 }}></Grid>
      ) : (
        renderCvDetails()
      )}
    </>
  );
};

export default RequestedCvScreenDrwaer;
