import React, { useState } from "react"
import { Typography, Box, Card, Grid } from "@mui/material"
import { makeStyles } from "@mui/styles"
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded"
import useAuth from "hooks/useAuth"
import { getUserRole } from "helpers/AuthUtils"
import { useTranslation } from "react-i18next"
const useStyles = makeStyles({
  card: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    Padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    "&:hover": {
      "&:hover": { boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)" },
      cursor: "pointer",
    },
  },
  iconContainer: {
    // backgroundColor: 'rgba(175, 252, 65, 0.1)',
    borderRadius: "25px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    height: "50px",
    // padding: 10,
    margin: 20,
  },
  icon: {
    fontSize: "40px", // Adjust the icon size as needed
    color: "#6BD425", // Use the specified icon color
  },
  textContainer: {
    // margin: '20px',
    color: "#000000",
    flex: 1,
    width: "250px",
  },
  favoritesContainer: {
    display: "flex",
    alignItems: "center",
    // flex: 1,
    // flexDirection: 'row',
    width: "120px",
    height: "48px",
  },
  favorites: {
    width: "50px",
    height: "30px",
    alignItems: "right",
    justifyContent: "right",
    textAlign: "right",
  },
})

const JobCard = ({ heading, subheading, image, color = "#ffffff", data }) => {
  const classes = useStyles()
  const isLoggedIn = useAuth()
  const role = getUserRole()
  const { t, i18n } = useTranslation()

  return (
    <Card className={classes.card} minHeight="xs" style={{ padding: 8 }}>
      <Grid container spacing={1}>
        <Grid item>
          <BusinessCenterRoundedIcon
            sx={{ width: 40, height: 40, color: "#8C9196" }}
          />
        </Grid>
        <Grid item xs>
          <Box className={classes.profileDetails}>
            <Typography
              variant="h6"
              fontSize="0.9375rem"
              fontWeight={800}
              className={classes.title}
            >
              {heading}
            </Typography>

            {isLoggedIn && (role === 1 || role === 5) ? (
              <>
                <Typography
                  variant="body2"
                  fontSize={"0.6875rem"}
                  fontWeight={700}
                  sx={{ color: "#010A03CC" }}
                >
                  {data?.job_post?.employer.name}
                </Typography>
                <Typography
                  variant="body2"
                  fontSize={"0.6875rem"}
                  fontWeight={700}
                  sx={{ color: "#010A03CC" }}
                >
                  {i18n.language === "bn"
                    ? data?.job_post?.job_type.name_bn
                    : data?.job_post?.job_type.name}{" "}
                  | {data?.job_post?.job_exp_min} Years + exp
                  {/* {data?.job_post?.job_type.name} |{" "}
                  {data?.job_post?.job_exp_min} Years + exp */}
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  variant="body2"
                  fontSize={"0.6875rem"}
                  fontWeight={700}
                  sx={{ color: "#010A03CC" }}
                >
                  {data?.organization}
                </Typography>
                <Typography
                  variant="body2"
                  fontSize={"0.6875rem"}
                  fontWeight={700}
                  sx={{ color: "#010A03CC" }}
                >
                  {data?.job_type} | {data?.job_exp_min} Years + exp
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}

export default JobCard
