import { Stack } from "@mui/material"
import { UrlBuilder } from "helpers/UrlBuilder"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { callApi, selectApi } from "store/reducers/apiSlice"

const FilterJobs = ({ page, pageSize, isLoading, setIsLoading }) => {
  const [category, setCategoryId] = useState("")
  const [status, setStatus] = useState("")
  const dispatch = useDispatch()
  const {
    jobCategory,
    allCvRequests = {
      data: [],
    },
    usersInfos,
  } = useSelector(selectApi)

  const listOfEmployerUserIds = allCvRequests.data?.job_request?.map(
    (el) => el.employer_user_id
  )
  const listOfWorkerUserIds = allCvRequests.data?.job_request?.map(
    (el) => el.worker_user_id
  )
  const userIds = [...listOfEmployerUserIds, ...listOfWorkerUserIds]
  React.useEffect(() => {
    setIsLoading(true)
    fetchData()
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [])

  function fetchData() {
    dispatch(
      callApi({
        operationId: UrlBuilder.jobServiceApi(`job/categories`),
        output: "categories",
      })
    )
  }
  return (
    <Stack mb={2} spacing={2} direction="row">
      {/* <Select
        label="Account Type"
        sx={{ width: 150 }}
        onChange={(e) => setCategoryId(e.target.value)}
      >
        {jobCategory?.data.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        ))}
      </Select>
      <Select
        label="Account Type"
        sx={{ width: 150 }}
        onChange={(e) => setCategoryId(e.target.value)}
      >
        {jobCategory?.data.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        ))}
      </Select>

      <Button
        startIcon={<Clear />}
        color="secondary"
        variant="contained"
        onClick={clearFilter}
      >
        CLEAR FILTER
      </Button> */}
    </Stack>
  )
}

export default FilterJobs
