import { InputLabel, TextField } from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  selectIcon: {
    "& .MuiSelect-icon": {
      color: "#00D42A",
    },
  },
  disabled: {
    border: "none",
    outline: "none",
    "&:hover": {
      border: "none",
      outline: "none",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
}));
const CustomTextField = (props) => {
  const { inputLabel, select, children, ...otherProps } = props;
  const classes = useStyles();

  return (
    <div>
      <InputLabel sx={{ fontWeight: 600, mb: 1, color: "#010A03" }}>
        <strong>{inputLabel || null}</strong>
      </InputLabel>
      {select ? (
        <TextField
          select
          variant="outlined"
          {...otherProps}
          fullWidth
          className={`${select ? classes.selectIcon : ""} ${
            otherProps.disabled ? classes.disabled : ""
          }`}
          SelectProps={{
            IconComponent: ExpandMoreOutlinedIcon,
          }}
        >
          {children}
        </TextField>
      ) : (
        <TextField variant="outlined" {...otherProps} fullWidth />
      )}
    </div>
  );
};

export default CustomTextField;
